import { useCallback, useEffect } from "react";
import { useRouter } from "next/router";
import { connect } from "react-redux";
import { getPreferences } from "../../utilities/api/preferencesApi";
import { setPreference } from "../../actions/preferenceActions";
import { setMode } from "../environment/actions";
import { getManageType } from "../../utilities/serverUtilities";
import {
  PREFERENCE_ARRIVAL,
  PREFERENCE_DEPARTURE,
  PREFERENCE_SUBJECTS,
  PREFERENCE_VOUCHER,
} from "../../constants/preferences";
import { transformQuerySubjects } from "../../utilities/preferenceUtilities";
import qs from "query-string";
import { getReservationState } from "../../utilities/api/reservationApi";
import { reservationSelector } from "../reservation/selectors";
import { reservationRestoreSession } from "../reservation/actions";

function SessionManager({
  reservation,
  restoreReservation,
  setManagementMode,
  setArrivalPreference,
  setDeparturePreference,
  setVoucherPreference,
  setSubjectsPreference,
}) {
  const router = useRouter();
  const { query } = router;
  const { manage, slug } = query;

  const processEnvironmentState = useCallback(
    (manage, slug) => {
      if (manage === "true") {
        setManagementMode("manage", getManageType(slug));
      }
    },
    [setManagementMode]
  );

  const processPreferencesState = (data) => {
    const queryString = qs.parse(window.location.search);
    updateArrivalPreference(queryString, data.arrival);
    updateDeparturePreference(queryString, data.departure);
    updateVoucherPreference(queryString, data.voucher);
    updateSubjectsPreference(queryString, data.subjects);
  };

  const updateArrivalPreference = (queryString, arrivalState) => {
    const arrival = queryString.arrival || arrivalState;
    if (arrival) {
      setArrivalPreference(arrival);
    }
  };

  const updateDeparturePreference = (queryString, departureState) => {
    const departure = queryString.departure || departureState;
    if (departure) {
      setDeparturePreference(departure);
    }
  };

  const updateVoucherPreference = (queryString, voucherState) => {
    const voucher = queryString.voucher || voucherState;
    if (voucher) {
      setVoucherPreference(voucher);
    }
  };

  const updateSubjectsPreference = (queryString, subjectsState) => {
    if (queryString["subjects[]"]) {
      const subjects = transformQuerySubjects(queryString["subjects[]"]);
      setSubjectsPreference(subjects);
    } else if (subjectsState) {
      setSubjectsPreference(subjectsState);
    }
  };

  useEffect(() => {
    getPreferences().then((response) => {
      const data = response?.data;
      if (data != null) {
        processPreferencesState(data);
      }
    });
  }, []);

  useEffect(() => {
    if (manage == null) return null;
    processEnvironmentState(manage, slug);
  }, [manage, slug, processEnvironmentState]);

  // Restore reservation from server session.
  useEffect(() => {
    if (reservation?.reservationId) return;
    getReservationState().then((response) => {
      const sessionReservation = response?.data ?? {};
      if (
        sessionReservation?.reservationId &&
        sessionReservation?.reservationId !== reservation.reservationId
      ) {
        restoreReservation(sessionReservation);
      }
    });
  }, [reservation, restoreReservation]);
  return null;
}

const mapDispatchToProps = (dispatch) => ({
  setManagementMode: (mode, managementType) =>
    dispatch(setMode(mode, managementType)),
  setArrivalPreference: (arrival) =>
    dispatch(setPreference(PREFERENCE_ARRIVAL, arrival)),
  setDeparturePreference: (departure) =>
    dispatch(setPreference(PREFERENCE_DEPARTURE, departure)),
  setVoucherPreference: (voucher) =>
    dispatch(setPreference(PREFERENCE_VOUCHER, voucher)),
  setSubjectsPreference: (subjects) =>
    dispatch(setPreference(PREFERENCE_SUBJECTS, subjects)),
  restoreReservation: (reservation) =>
    dispatch(reservationRestoreSession(reservation)),
});

const mapStateToProps = (state) => ({
  reservation: reservationSelector(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(SessionManager);
