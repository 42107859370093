// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { GlobalStyle } from "@libema/design-system";
import { captureException } from "@sentry/nextjs";
import Axios from "axios";
import moment from "moment";
import {
  GetStaticPaths,
  GetStaticPathsContext,
  GetStaticProps,
  GetStaticPropsContext,
} from "next";
import Head from "next/head";
import { withRouter } from "next/router";
import QueryString from "qs";
import { ParsedUrlQuery } from "querystring";
import { Component, FC } from "react";
import { connect } from "react-redux";
import { Store } from "redux";
import {
  blockSchemaChildren,
  getBlocks,
  getHeaderFooterBlocks,
} from "../actions/contentActions";
import {
  clearAlternateLangUrls,
  setAlternateLangUrls,
} from "../actions/contentfulActions";
import { SET_PREFERENCE, setPreference } from "../actions/preferenceActions";
import { ProgressIndicator } from "../components/BlockMap";
import DocumentHead from "../components/DocumentHead";
import InternalError from "../components/InternalError";
import {
  PREFERENCE_LOCALE,
  PREFERENCE_VOUCHER,
} from "../constants/preferences";
import CustomStyle from "../globalStyle";
import setTranslationLocale from "../locales/util";
import { setContent, setMode } from "../modules/environment/actions";
import { initializeReservation } from "../modules/reservation/actions";
import { wrapper } from "../redux/store";
import { placeholder } from "../utilities/blocks";
import { getLocaleFromSlug } from "../utilities/localeUtilities";
import redirectUtil from "../utilities/redirects";
import { getManageType } from "../utilities/serverUtilities";

// Api
import {
  ApiError,
  checkRequiredEnvVariables,
  fetchLocales,
  fetchPage,
  fetchSitemapUrls,
  Locale,
  Page as PageProps,
  // Types
  Program,
  Topic,
} from "@libema/content-sdk";
import { pageUtils, PlainLayout } from "@libema/core";

// Local Components
import LegacyHeaderBackground from "../components/LegacyHeaderBackground";
import Templates from "../components/templates";

// Templates
import { normalize } from "normalizr";
import SessionManager from "../modules/preferences/sessionManager";
import analyticsUtils from "../utilities/analyticsUtils";
import { getWithExpiry, setWithExpiry } from "../utilities/localStorage";

const baseUrl = process.env.NEXT_PUBLIC_BASE_PATH || "";

enum PageType {
  LEGACY,
  STANDARD,
}

type WrappedGetStaticPropsContext = GetStaticPropsContext & { store: Store };

export const getStaticProps: GetStaticProps = wrapper.getStaticProps(
  async (context: WrappedGetStaticPropsContext) => {
    checkRequiredEnvVariables();

    const { slug, query, locale, locales } = pageUtils.getProperties(context);
    const { store, params, preview } = context;

    // Check for redirects
    const slugWithoutLeadingSlash = slug?.startsWith("/")
      ? slug.substring(1)
      : slug;
    const redirectPage = await redirectUtil.findRedirect(
      slugWithoutLeadingSlash,
      locale
    );
    if (redirectPage) {
      return {
        redirect: {
          destination: redirectPage.destination,
          permanent: redirectPage.permanent,
        },
      };
    }

    store.dispatch({
      type: SET_PREFERENCE,
      payload: { name: PREFERENCE_LOCALE, value: locale },
    });

    store.dispatch(clearAlternateLangUrls());

    if (preview) {
      store.dispatch(setMode("manage", getManageType(params)));
    }

    // First try jstack CMS
    const joinedSlug = Array.isArray(params?.slug)
      ? params?.slug.join("/")
      : params?.slug;
    const legacySlug = !joinedSlug && locale === "nl" ? "index" : joinedSlug;
    const localizedLegacySlug =
      locale && locale !== "nl"
        ? `${locale}${(legacySlug && `/${legacySlug}`) || ""}`
        : legacySlug;

    try {
      const content = await store.dispatch(
        getBlocks(
          localizedLegacySlug,
          (response) => {
            store.dispatch(setContent(response.id));
            // eslint-disable-next-line  @typescript-eslint/no-explicit-any
          },
          preview
        ) as any
      );
      const normalized = normalize(content, { blocks: [blockSchemaChildren] });
      const blocks = normalized.entities.blocks;
      const children = Object.values(blocks || {}).filter(
        (b) => b && b.parentId === undefined
      );

      // Delete duplicate blocks
      delete content?.blocks;

      return {
        props: {
          content,
          blocks,
          children,
          locale,
          pageType: PageType.LEGACY,
          preview: preview ?? false,
        },
        revalidate: process.env.CACHE_REVALIDATION
          ? Number(process.env.CACHE_REVALIDATION)
          : 1,
      };
    } catch (legacyError) {
      if (legacyError.response?.status !== 404) {
        try {
          captureException(legacyError);
          console.error(legacyError);
        } catch (error) {
          console.error(erro);
        }
        return {
          props: {
            locale,
            internalError: true,
            pageType: PageType.LEGACY,
          },
          // Check if error has been solved every 10 seconds.
          revalidate: 10,
        };
      }

      // Second try Contentful
      try {
        // TODO: implement proper throttling of Contentful API requests
        // await new Promise((resolve) => setTimeout(resolve, 200));

        // Fetch Legacy header
        const content = await store.dispatch(
          getHeaderFooterBlocks(locale) as any
        );

        const normalized = normalize(content, {
          blocks: [blockSchemaChildren],
        });
        const blocks = normalized.entities.blocks;
        const children = Object.values(blocks || {}).filter(
          (b) => b && b.parentId === undefined
        );

        const [page, availableLocales] = await Promise.all([
          fetchPage(slug, query, locale, locales, preview),
          fetchLocales(),
        ]);

        // Store Contentful alternate language urls so that the legacy
        // menu language switch can use them.
        await store.dispatch(setAlternateLangUrls(slug, page.canonicalUrls));

        return {
          props: {
            ...page,
            blocks,
            children,
            availableLocales,
            pageType: PageType.STANDARD,
            preview: preview ?? false,
          },
          revalidate: process.env.CACHE_REVALIDATION
            ? Number(process.env.CACHE_REVALIDATION)
            : 1,
        };
      } catch (error) {
        const statusCode = error instanceof ApiError ? error.statusCode : -1;

        switch (statusCode) {
          case 301: {
            const { details } = error;
            return {
              redirect: {
                destination: details?.to || "/",
                permanent: true,
              },
            };
          }
          case 404: {
            // Show legacy 404 page
            const normalized = normalize(legacyError.response.data, {
              blocks: [blockSchemaChildren],
            });
            const blocks = normalized.entities.blocks;
            const children = Object.values(blocks || {}).filter(
              (b) => b && b.parentId === undefined
            );

            return {
              props: {
                content: legacyError.response.data,
                blocks,
                children,
                locale,
                pageType: PageType.LEGACY,
              },
              // Check if page has been created every 10 seconds
              revalidate: 10,
            };
          }
          default: {
            captureException(
              JSON.stringify(
                {
                  message: error.message,
                  statusCode: error instanceof ApiError ? error.statusCode : 0,
                  details: error instanceof ApiError ? error.details : null,
                },
                null,
                4
              )
            );

            throw error;
          }
        }
      }
    }
  }
);

export const getStaticPaths: GetStaticPaths = async ({
  locales,
  defaultLocale,
}: GetStaticPathsContext) => {
  const { data } = await Axios.get(
    `${process.env.NEXT_PUBLIC_API_BASE_URL}/sitemap.xml`
  );
  const legacyUrls = data.matchAll(/<loc>http[s]*:\/\/[a-z.]+(.+?)<\/loc>/g);

  // Filter out redirects during static rendering phase.
  const redirects = await redirectUtil.getRedirects();
  const redirectUrls = redirects?.map(({ source }) => source) || [];

  let paths: Array<{ params: ParsedUrlQuery; locale?: string }> = [];
  for (const legacyUrl of legacyUrls) {
    // Ignore redirect urls during static rendering phase.
    if (
      redirectUrls.indexOf(legacyUrl[1]) > 0 ||
      redirectUrls.indexOf(legacyUrl[1].substr(1)) > 0
    ) {
      continue;
    }

    let locale;
    switch (legacyUrl[1].substr(0, 3)) {
      case "/de":
        locale = "de";
        break;
      case "/fr":
        locale = "fr";
        break;
      case "/en":
        locale = "en";
        break;
      default:
        locale = "nl";
    }
    paths = [
      ...paths,
      {
        params: {
          slug: legacyUrl[1]
            .substr(1)
            .split("/")
            .filter(
              (s, idx) => !["en", "de", "fr", "nl"].includes(s) || idx !== 0
            )
            .filter((part) => part !== ""),
        },
        locale,
      },
    ];
  }

  for (const locale of locales || []) {
    const urls = await fetchSitemapUrls(locale, defaultLocale || "nl");
    const slugs = urls
      .filter(({ url }) => url !== "/")
      .map(({ url }) => ({
        params: {
          slug: url
            .substr(1)
            .split("/")
            .filter(
              (s, idx) => !["en", "de", "fr", "nl"].includes(s) || idx !== 0
            )
            .filter((part) => part !== ""),
        },
        locale,
      }));
    paths = [...paths, ...slugs];
  }

  // Limit generated SSG pages to max 50 pages for non-production environments
  // to reduce build times.

  paths = paths
    .sort((a, b) => (a.params.slug.length < b.params.slug.length ? -1 : 1))
    .slice(0, 50);

  return {
    paths,
    fallback: "blocking",
  };
};

type Props = PageProps<Topic | Program> & {
  availableLocales: Locale[];
  postSubscriptions: any;
  content: any;
  blocks: any;
  children: any;
  initializeReservation: any;
  locale: string;
  router: any;
  isFetching: boolean;
  setLocale: any;
  setVoucher: any;
  internalError: boolean;
  pageType: PageType;
  preview: boolean;
};

class Index extends Component<Props> {
  constructor(props) {
    super(props);

    this.updateLocale();
  }

  componentDidMount = () => {
    this.updateLocale();
    this.initializeForBooking();
    this.trackPaymentConfirmation();

    // Fix for shallow router bug in nextjs
    this.props.router.beforePopState(({ as, options }) => {
      if (options.shallow) {
        this.props.router.replace(as);
        return false;
      }
      return true;
    });
  };

  componentDidUpdate = (prevProps) => {
    const { router } = this.props;
    const [pathname] = router.asPath.split("?");
    const [prevPathname] = prevProps.router.asPath.split("?");
    if (prevPathname !== pathname) {
      this.initializeForBooking();
      this.updateLocale();
    }
  };

  initializeForBooking = () => {
    const { router, initializeReservation } = this.props;
    const [pathname] = router.asPath.split("?");
    const body = document.getElementsByTagName("body")[0];
    const queryVoucher = QueryString.parse(router.asPath).voucher;
    const localStorageVoucher = getWithExpiry("voucher");

    if (queryVoucher) {
      const voucher = setWithExpiry("voucher", queryVoucher);
      if (voucher) this.props.setVoucher(voucher);
    } else {
      if (localStorageVoucher) this.props.setVoucher(localStorageVoucher);
    }

    if (
      pathname === "/boeking" ||
      pathname === "/booking" ||
      pathname === "/en/booking" ||
      pathname === "/de/buchung" ||
      pathname === "/fr/reservation"
    ) {
      initializeReservation({
        voucher_code: queryVoucher || localStorageVoucher?.value,
        ...router.query,
      });
    }

    if (
      (pathname.startsWith("/boeking") && pathname !== "/boeking-storing") ||
      pathname.startsWith("/booking") ||
      (pathname.startsWith("/en/booking") &&
        !pathname !== "/en/booking-malfunction-form") ||
      (pathname.startsWith("/de/buchung") &&
        !pathname !== "/de/buchung-storung-formular") ||
      (pathname.startsWith("/fr/reservation") &&
        !pathname !== "/fr/reservation-probleme-forme")
    ) {
      body.id = "booking-app";
    } else {
      body.id = "";
    }
  };

  trackPaymentConfirmation = () => {
    const { router } = this.props;
    const [pathname] = router.asPath.split("?");
    if (
      pathname.startsWith("/boeking/betalingsbevestiging") ||
      pathname.startsWith("/booking/paymentconfirmation") ||
      pathname.startsWith("/buchung/zahlungsbestatigung") ||
      pathname.startsWith("/reservation/confirmation-de-paiement") ||
      pathname.startsWith("/en/booking/paymentconfirmation") ||
      pathname.startsWith("/de/buchung/zahlungsbestatigung") ||
      pathname.startsWith("/fr/reservation/confirmation-de-paiement")
    ) {
      analyticsUtils.handlePaymentConfirmationView();
    }
  };

  updateLocale = () => {
    const { router, setLocale } = this.props;
    const locale = getLocaleFromSlug(router.locale);
    moment.locale(locale);
    setLocale(locale);
    setTranslationLocale(locale);
  };

  render() {
    const {
      content,
      blocks,
      children,
      isFetching,
      internalError,
      metaTitle,
      metaDescription,
      canonicalUrls,
      topic,
      sections,
      router: { defaultLocale, basePath, asPath },
      pageType,
      preview = false,
    } = this.props;

    if (pageType === PageType.LEGACY) {
      if (internalError) {
        return <InternalError />;
      }

      return (
        <>
          {content && <DocumentHead content={content} />}
          <SessionManager />
          <CustomStyle />
          {isFetching && !content && (
            <ProgressIndicator isHidden={!isFetching} />
          )}
          {placeholder(blocks, 1, null, children)}
          {placeholder(blocks, 0, null, children)}
          <footer>
            <div>{placeholder(blocks, 3, null, children)}</div>
            <div className="footer footer-sm p-y-md">
              {placeholder(blocks, 4, null, children)}
            </div>
          </footer>
        </>
      );
    }

    const templateType = topic?.type || "landingPage";
    const DynamicLayout = Templates[templateType] as FC<Props>;

    const showLegacyHeaderBackground = templateType === "program";

    return (
      <>
        <Head>
          {preview ? (
            <title>[Preview]: {metaTitle}</title>
          ) : (
            <title>{metaTitle}</title>
          )}
          <meta name="description" content={metaDescription} />
          <link rel="canonical" href={`${baseUrl}${asPath}`} />

          {canonicalUrls?.map(({ url, locale }) => {
            if (locale === defaultLocale)
              return (
                <link
                  key={locale}
                  rel="alternate"
                  hrefLang={locale}
                  href={`${baseUrl}${basePath}${url}`}
                />
              );
            return (
              <link
                key={locale}
                rel="alternate"
                hrefLang={locale}
                href={`${baseUrl}${basePath}/${locale}${url}`}
              />
            );
          })}
        </Head>
        <CustomStyle />
        <GlobalStyle />
        <SessionManager />
        <PlainLayout className="legacy">
          {placeholder(blocks, 1, null, children)}
          {showLegacyHeaderBackground && <LegacyHeaderBackground />}
          <DynamicLayout {...this.props} />
          <footer>
            <div>{placeholder(blocks, 3, null, children)}</div>
            <div className="footer footer-sm p-y-md">
              {placeholder(blocks, 4, null, children)}
            </div>
          </footer>
        </PlainLayout>
      </>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {};
};

const mapDispatchToProps = (dispatch, props) => ({
  initializeReservation: (reservation) =>
    dispatch(initializeReservation(reservation)),
  setLocale: (locale) => dispatch(setPreference("locale", locale)),
  setVoucher: (voucher) => dispatch(setPreference(PREFERENCE_VOUCHER, voucher)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Index));
