import { Section, H2 } from "@libema/design-system";
import { createGlobalStyle } from "styled-components";

const CustomStyle = createGlobalStyle`
  ${Section.Wrapper} ${Section.Header} ${H2} {
    font-family: 'Anton';
    font-weight: 300;
    font-size: 2rem;
  }

`;

export default CustomStyle;
