import * as t from './actionTypes'

export function setContent (content) {
  return {
    type: t.SET_CONTENT,
    content
  }
}

export function setMode (manageMode = 'public', manageType = 'content') {
  return {
    type: t.SET_MODE,
    manageMode,
    manageType
  }
}
