// Get the slug of the request and remove trailing slashes
export const getSlug = req => (req.params['0'] === '/' ? '/index' : req.params['0'].replace(/\/$/, ''))

export const getManageType = (params) => {
  if (!Array.isArray(params?.slug)) {
    return 'content'
  }

  if (params.slug.includes('templates')) {
    return 'template'
  }

  return 'content'
}
