import styled from "styled-components";

import { DEVICE_LG_UP } from "@libema/design-system";

const LegacyHeaderBackground = styled.div`
  display: none;
  height: 164px;
  background: ${({ theme }) => theme.color.secondary};

  @media ${DEVICE_LG_UP} {
    & {
      display: block;
    }
  }
`;

export default LegacyHeaderBackground;
