import React from 'react'
import trans from 'counterpart'
import styled from 'styled-components'
import { Container } from 'styled-bootstrap-grid'
import { isBeekseBergen } from "../utilities/common";

const logo = isBeekseBergen ? '/img/logo.svg' : '/img/logo-dierenbos.svg'

const Wrapper = styled.div`
  .logo {
    text-align: center;
    background-color: ${({ theme }) => isBeekseBergen ? theme.color.neutralMedium : 'transparent'};

    img {
      padding: 16px;
      height: 120px;
    }
  }

  ${Container} {
    .message {
      margin-top: 24px;
      text-align: center;
    }

    ul {
      display: grid;
      justify-content: space-around;
      list-style: none;
    }

    li:before {
      font-family: 'Material Icons';
      margin-right: 8px;
      vertical-align: middle;
      font-size: 24px;
    }

    li.email:before {
      content: 'email'
    }
    li.phone:before {
      content: 'phone'
    }
  }
`

const InternalError = () => (
  <Wrapper>
    <div className='logo'>
      <img src={logo} />
    </div>
    <Container>
      <div className='message'>
        <h1>{trans('error.internal.title')}</h1>
        <p dangerouslySetInnerHTML={{__html: trans('error.internal.message')}} />
      </div>
      <ul>
        <li className='email'>
          <a href={`mail:${trans('error.internal.email')}`}>{trans('error.internal.email')}</a>
        </li>
        <li className='phone'>
          <a href={`tel:${trans('error.internal.phoneVal')}`}>{trans('error.internal.phone')}</a>
        </li>
      </ul>
    </Container>
  </Wrapper>
)

export default InternalError
