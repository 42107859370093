import { useRouter } from 'next/router'
import Head from 'next/head'
import PropTypes from 'prop-types'
import { getSrc } from '../utilities/mediaUtilities'

const DocumentHead = ({ content: { title, description, indexable, cover_image, translation_group: translationGroup } }) => {
  const router = useRouter()

  return (
    <Head>
      <title>{title}</title>
      <meta key='robots' name='robots' content={process.env.NEXT_PUBLIC_ENV === 'production' && indexable ? 'index, follow' : 'noindex, nofollow'} />
      <meta key='description' name='description' content={description} />
      <link key='canonical' rel='canonical' href={`https://${process.env.NEXT_PUBLIC_BASE_URL}${router.asPath.split('?')[0]}`} />
      <link key='icon' rel='icon' type='image/x-icon' href='/favicon.ico' />
      {translationGroup && !Array.isArray(translationGroup) && Object.keys(translationGroup).map(language => (<link key={`alternate_${language}`} rel='alternate' hrefLang={language} href={`https://${process.env.NEXT_PUBLIC_BASE_URL}${translationGroup[language]}`} />))}
      <meta key='og:title' property='og:url' content={`https://${process.env.NEXT_PUBLIC_BASE_URL}${router.asPath}`} />
      <meta key='og:title' property='og:title' content={title} />
      <meta key='og:description' property='og:description' content={description} />
      {cover_image && (
        <>
          <meta key='og:image' property='og:image' content={getSrc(cover_image, 'thumbnail_lg')} />
          <meta key='og:image:secure_url' property='og:image:secure_url' content={getSrc(cover_image, 'thumbnail_lg')} />
        </>
      )}
    </Head>
  )
}

DocumentHead.propTypes = {
  content: PropTypes.object
}

export default DocumentHead
